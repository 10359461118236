import _ from 'lodash';
import moment from 'moment';

export default ({
    Vue, // the version of Vue being used in the VuePress app
    options, // the options for the root Vue instance
    router, // the router instance for the app
    siteData // site metadata
  }) => {
    Vue.prototype._ = _
    Vue.filter('isoDate', function(value) {
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
      }
    })
    // ...apply enhancements to the app
  }
  
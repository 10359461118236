<template>
    <div class="post">
        <div class="preview-details">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="full" width="24" height="24" viewBox="0 0 24.00 24.00" enable-background="new 0 0 24.00 24.00" xml:space="preserve">
                <path fill="#000000" fill-opacity="1" stroke-width="0.2" stroke-linejoin="round" d="M 18.9994,18.9981L 4.9994,18.9981L 4.9994,7.99807L 18.9994,7.99807M 15.9994,0.998068L 15.9994,2.99807L 7.9994,2.99807L 7.9994,0.998068L 5.9994,0.998068L 5.9994,2.99807L 4.9994,2.99807C 3.8934,2.99807 3.00939,3.89306 3.00939,4.99807L 2.9994,18.9981C 2.9994,20.1021 3.8934,20.9981 4.9994,20.9981L 18.9994,20.9981C 20.1024,20.9981 20.9994,20.1021 20.9994,18.9981L 20.9994,4.99807C 20.9994,3.89306 20.1024,2.99807 18.9994,2.99807L 17.9994,2.99807L 17.9994,0.998068M 16.9994,11.9981L 11.9994,11.9981L 11.9994,16.9981L 16.9994,16.9981L 16.9994,11.9981 Z " />
            </svg>
            <span class="preview-details-text"> {{ post.frontmatter.created | isoDate }}</span>
        </div>
        <div class="preview-title">
            <router-link :to="post.path">
                {{ post.title }}
            </router-link>
        </div>
        <hr/>
        <div class="tags">
            <div class="md-chip" v-for="tag in post.frontmatter.tags">{{tag}}</div>
        </div>
    </div>
</template>

<script>

export default {
  props: ["post"],
  data() {
    return {};
  }
};
</script>

<style lang="stylus">
@import './styles/config.styl';

hr {
    width: 11rem;
    height: 0.2rem;
    margin: 10px auto;
    border: 0;
    background: $accentColor;
}

.preview-details {
    flex-direction: column;
    text-align: left;
    margin-left: 1rem;
    margin-top: 1rem;
    text-justify: center;
}

.preview-details-text {
    vertical-align: top;
}

.preview-title {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 29px;
    text-align: center;
    padding-top: 0.2rem;
}

.post {
    margin-top: 2rem;
    margin-bottom: 2rem;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    // overflow: hidden
    justify-content: center;
    display: flex;
    padding-bottom: 1rem;
    border-radius: 10px;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.5);
    outline: 1px solid transparent;
}

.md-chip {
    display: inline-block;
    padding: 0 12px;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    padding-top: 3px;
    padding-bottom: 3px;
    color: $md-chip-text;
    border-radius: 32px;
    font-size: 16px;
    background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}
</style>
